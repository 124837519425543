<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo" style="margin-bottom:0px">
          <vuexy-logo />
        </b-link>

        <!-- form login -->
        <validation-observer ref="loginValidation" v-if="step === 1">
          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- email -->
            <b-form-group label-for="username" :label="$i18n.messages.Email">
              <validation-provider
                #default="{ errors }"
                :name="$i18n.messages.Email"
                rules="required|email"
              >
                <b-form-input
                  id="username"
                  v-model="username"
                  name="login-username"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$i18n.messages.Email"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group label-for="password" :label="$i18n.messages.password">
              <validation-provider
                #default="{ errors }"
                :name="$i18n.messages.password"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="login-password"
                    :placeholder="$i18n.messages.password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <p class="forgot-password text-right mt-1 mb-2">
              <b-button
                variant="link"
                style="border-style: none;color: #B15C36"
                @click="step = 4"
              >
                {{ $i18n.messages.Forgot }}
              </b-button>
            </p>

            <p>
              {{ $i18n.messages.Advise1 }}
            </p>
            <p>
              {{ $i18n.messages.Advise2 }}
            </p>
            <p>
              {{ $i18n.messages.Advise3 }}
              <a href="mailto:dune@duneceramics.com">dune@duneceramics.com</a>
            </p>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              @click="validationFormLogin"
              style="margin-bottom: 20px;margin-top: 30px;"
            >
              {{ $i18n.messages.login }}
            </b-button>

            <!-- submit button -->
            <b-button
              variant="primary"
              block
              @click="step = 2"
              style="margin-bottom: 20px;margin-top: 20px;"
            >
              {{ $i18n.messages.Register }}
            </b-button>
          </b-form>
        </validation-observer>

        <!-- form registro -->

        <validation-observer ref="signinValidation" v-if="step === 2">
          <feather-icon
            class="cursor-pointer"
            icon="ArrowLeftIcon"
            @click="step = 1"
            size="2x"
          />

          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- email -->
            <h3 class="font-weight-bolder mb-2">
              {{ $i18n.messages.RegisterYou }}
            </h3>
            <p class="text-gray">
              <strong>{{ $i18n.messages.PersonalData }}</strong>
            </p>
            <b-form-group
              label-for="signin-username"
              :label="$i18n.messages.usuario"
            >
              <validation-provider
                #default="{ errors }"
                :name="$i18n.messages.usuario"
                rules="required"
              >
                <b-form-input
                  id="signin-username"
                  v-model="signinUsername"
                  name="signin-username"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$i18n.messages.usuario"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group label-for="email" :label="$i18n.messages.Email">
              <validation-provider
                #default="{ errors }"
                :name="$i18n.messages.Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="signinEmail"
                  name="signin-email"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$i18n.messages.Email"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- password -->
            <b-form-group
              label-for="signin-password"
              :label="$i18n.messages.password"
            >
              <validation-provider
                #default="{ errors }"
                :name="$i18n.messages.password"
                v-bind:rules="{
                  required: true,

                  regex: /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/,
                }"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="signin-password"
                    v-model="signinPassword"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="signin-password"
                    :placeholder="$i18n.messages.password"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  class="text-danger"
                  v-if="errors[0] && errors[0].includes('formato')"
                >
                  {{ $i18n.messages.PassRestriction }}</small
                >
                <small class="text-danger" v-else>{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label-for="signin-language"
              :label="$i18n.messages.language"
            >
              <b-form-select
                id="signin-language"
                v-model="signinLanguage"
                :options="languageSelect"
                :placeholder="$i18n.messages.language"
                autofocus
              ></b-form-select>
            </b-form-group>

            <b-button
              :class="stepRegistro != 2 ? '' : 'd-none'"
              variant="primary"
              @click="stepRegistro = 2"
            >
              {{ $i18n.messages.Next }}
            </b-button>

            <div :class="stepRegistro == 2 ? '' : 'd-none'">
              <p class="text-gray">
                <strong> {{ $i18n.messages.CompanyData }}</strong>
              </p>

              <b-form-group
                label-for="signin-empresa"
                :label="$i18n.messages.empresa"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$i18n.messages.empresa"
                  rules="required"
                >
                  <b-form-input
                    id="signin-empresa"
                    v-model="signinEmpresa"
                    name="signin-empresa"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$i18n.messages.empresa"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label-for="signin-codcli"
                :label="$i18n.messages.ClientCode"
              >
                <validation-provider #default="{ errors }" name="CodCli">
                  <b-form-input
                    id="signin-codcli"
                    v-model="signinCodCli"
                    name="signin-codcli"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$i18n.messages.HelpCode"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group label-for="signin-cif" label="CIF">
                <validation-provider
                  #default="{ errors }"
                  name="CIF"
                  v-bind:rules="{
                    required: true,
                  }"
                >
                  <span style="font-size: 11px;color: #8080809c;">Máximo 30 caracteres. Mínimo 6. Sin espacio. Sin comillas. Solo letras y números.</span>
                  <b-form-input
                    id="signin-cif"
                    v-model="signinCif"
                    name="signin-cif"
                    :state="errors.length > 0 ? false : null"
                    placeholder="CIF"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label-for="signin-cargo"
                :label="$i18n.messages.cargo"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$i18n.messages.cargo"
                  rules="required"
                >
                  <b-form-input
                    id="signin-cargo"
                    v-model="signinCargo"
                    name="signin-cargo"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$i18n.messages.cargo"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label-for="signin-mobile"
                :label="$i18n.messages.mobile"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$i18n.messages.mobile"
                  v-bind:rules="{ required: false }"
                >
                  <span style="font-size: 11px;color: #8080809c;">Por favor, indica el teléfono con formato 'prefijo y número de teléfono sin espacios y sin 0' o "número de teléfono sin prefijo". No se admite el signo +</span>
                  <b-form-input
                    id="signin-mobile"
                    v-model="signinMobile"
                    name="signin-mobile"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$i18n.messages.mobile"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label-for="signin-country"
                :label="$i18n.messages.country"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$i18n.messages.country"
                  rules="required"
                >
                  <!--   <b-form-input
                    id="signin-country"
                    v-model="signinCountry"
                    name="signin-country"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$i18n.messages.country"
                    autofocus
                  /> -->
                  <b-form-select
                    id="signin-country"
                    v-model="signinCountry"
                    :options="countriesEs"
                    :placeholder="$i18n.messages.country"
                    autofocus
                    :state="errors.length > 0 ? false : null"
                  ></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                variant="primary"
                type="submit"
                block
                @click="validationFormSignin"
                style="margin-bottom: 20px;margin-top: 20px;"
              >
                {{ $i18n.messages.Register }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>

        <!-- form forgot -->
        <validation-observer ref="forgotValidation" v-if="step === 4">
          <feather-icon
            class="cursor-pointer"
            icon="ArrowLeftIcon"
            @click="step = 1"
            size="2x"
          />

          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- email -->
            <b-form-group label-for="email" :label="$i18n.messages.Email">
              <validation-provider
                #default="{ errors }"
                :name="$i18n.messages.Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="forgotEmail"
                  name="forgot-email"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$i18n.messages.Email"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              @click="validationFormForgot"
              style="margin-bottom: 20px;margin-top: 20px;"
            >
              {{ $i18n.messages.Send }}
            </b-button>
          </b-form>
        </validation-observer>

        <!-- form forgot -->
        <validation-observer ref="forgotValidation" v-if="step === 5">
          <feather-icon
            class="cursor-pointer"
            icon="ArrowLeftIcon"
            @click="step = 1"
            size="2x"
          />

          <h2 style="margin-left: 20px;margin-bottom: 20px;margin-top: 20px;">
            {{ $i18n.messages.ForgotMsg }}
          </h2>
        </validation-observer>

        <!-- form forgot -->
        <validation-observer ref="newPasswordValidation" v-if="step === 6">
          <feather-icon
            class="cursor-pointer"
            icon="ArrowLeftIcon"
            @click="step = 1"
            size="2x"
          />

          <b-form class="auth-login-form mt-2" @submit.prevent>
            <!-- password -->
            <b-form-group
              label-for="newPassword"
              :label="$i18n.messages.newpasswordlong"
            >
              <validation-provider
                #default="{ errors }"
                :name="$i18n.messages.newpassword"
                v-bind:rules="{
                  required: true,

                  regex: /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/,
                }"
                vid="confirm"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="newPassword"
                    v-model="newPassword"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="new-password"
                    :placeholder="$i18n.messages.newpassword"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <!--   <small class="text-danger">{{ errors[0] }}</small> -->
                <small
                  class="text-danger"
                  v-if="errors[0] && errors[0].includes('formato')"
                >
                  {{ $i18n.messages.PassRestriction }}</small
                >
                <small class="text-danger" v-else>{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label-for="repeatNewPassword"
              :label="$i18n.messages.newpassword2"
            >
              <validation-provider
                #default="{ errors }"
                :name="$i18n.messages.newpassword2"
                rules="required|confirmed:confirm"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="newpassword2"
                    v-model="newPassword2"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="new-password-2"
                    :placeholder="$i18n.messages.newpassword2"
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button
              variant="primary"
              type="submit"
              block
              @click="validationFormNewPassword"
              style="margin-bottom: 20px;margin-top: 20px;"
            >
              {{ $i18n.messages.Send }}
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Login v1 -->
    </div>
    <modal-disclaimer></modal-disclaimer>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BCard,
  BLink,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BCardTitle,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ModalDisclaimer from "@/views/ModalDisclaimer.vue";

export default {
  components: {
    BCard,
    BLink,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BCardTitle,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    ModalDisclaimer,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      stepRegistro: 1,
      status: "",
      password: "",
      newPassword: "",
      newPassword2: "",
      username: "",
      signinUsername: "",
      signinMobile: "",
      signinCodCli: "",
      signinEmpresa: "",
      signinCif: "",
      signinCargo: "",
      signinCountry: "ES",
      signinLanguage: "es",
      signinEmail: "",
      forgotEmail: "",
      signinPassword: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      step: 1,
      languageSelect: [
        { value: "es", text: "Español" },
        { value: "en", text: "English" },
      ],
      countriesEs: [
        { value: "ES", text: "España" },
        { value: "AF", text: "Afganistán" },
        { value: "AL", text: "Albania" },
        { value: "DE", text: "Alemania" },
        { value: "AD", text: "Andorra" },
        { value: "AO", text: "Angola" },
        { value: "AI", text: "Anguila" },
        { value: "AQ", text: "Antártida" },
        { value: "AG", text: "Antigua y Barbuda" },
        { value: "AN", text: "Antillas Holandesas (OBSOLETO)" },
        { value: "SA", text: "Arabia Saudí" },
        { value: "DZ", text: "Argelia" },
        { value: "AR", text: "Argentina" },
        { value: "AM", text: "Armenia" },
        { value: "AW", text: "Aruba" },
        { value: "AU", text: "Australia" },
        { value: "AT", text: "Austria" },
        { value: "AZ", text: "Azerbaiyán" },
        { value: "BS", text: "Bahamas" },
        { value: "BH", text: "Bahréin" },
        { value: "BD", text: "Bangladés" },
        { value: "BB", text: "Barbados" },
        { value: "BE", text: "Bélgica" },
        { value: "BZ", text: "Belice" },
        { value: "BJ", text: "Benín" },
        { value: "BM", text: "Bermudas" },
        { value: "BY", text: "Bielorrusia" },
        { value: "BO", text: "Bolivia" },
        { value: "BQ", text: "Bonaire, San Eustaquio y Saba" },
        { value: "BA", text: "Bosnia-Herzegovina" },
        { value: "BW", text: "Botsuana" },
        { value: "BR", text: "Brasil" },
        { value: "BN", text: "Brunei Darussalam" },
        { value: "BG", text: "Bulgaria" },
        { value: "BF", text: "Burkina Faso" },
        { value: "BI", text: "Burundi" },
        { value: "BT", text: "Bután" },
        { value: "CV", text: "Cabo Verde" },
        { value: "KH", text: "Camboya" },
        { value: "CM", text: "Camerún" },
        { value: "CA", text: "Canadá" },
        { value: "TD", text: "Chad" },
        { value: "CL", text: "Chile" },
        { value: "CN", text: "China" },
        { value: "CY", text: "Chipre" },
        { value: "VA", text: "Ciudad del Vaticano" },
        { value: "CO", text: "Colombia" },
        { value: "KM", text: "Comoras" },
        { value: "CG", text: "Congo" },
        { value: "KP", text: "Corea del Norte" },
        { value: "KR", text: "Corea del Sur" },
        { value: "CI", text: "Costa de Marfil" },
        { value: "CR", text: "Costa Rica" },
        { value: "HR", text: "Croacia" },
        { value: "CU", text: "Cuba" },
        { value: "CW", text: "Curaçao" },
        { value: "DK", text: "Dinamarca" },
        { value: "DM", text: "Dominica" },
        { value: "EC", text: "Ecuador" },
        { value: "EG", text: "Egipto" },
        { value: "SZ", text: "El Reino de Eswatini" },
        { value: "SV", text: "El Salvador" },
        { value: "AE", text: "Emiratos Árabes Unidos" },
        { value: "ER", text: "Eritrea" },
        { value: "SK", text: "Eslovaquia" },
        { value: "SI", text: "Eslovenia" },

        { value: "US", text: "Estados Unidos" },
        { value: "EE", text: "Estonia" },
        { value: "ET", text: "Etiopía" },
        { value: "RU", text: "Federación Rusa" },
        { value: "PH", text: "Filipinas" },
        { value: "FI", text: "Finlandia" },
        { value: "FJ", text: "Fiyi" },
        { value: "FR", text: "Francia" },
        { value: "GA", text: "Gabón" },
        { value: "GM", text: "Gambia" },
        { value: "GE", text: "Georgia" },
        { value: "GH", text: "Ghana" },
        { value: "GI", text: "Gibraltar" },
        { value: "GD", text: "Granada" },
        { value: "GR", text: "Grecia" },
        { value: "GL", text: "Groenlandia" },
        { value: "GP", text: "Guadalupe" },
        { value: "GU", text: "Guam" },
        { value: "GT", text: "Guatemala" },
        { value: "GF", text: "Guayana Francesa" },
        { value: "GG", text: "Guernesey" },
        { value: "GN", text: "Guinea" },
        { value: "GQ", text: "Guinea Ecuatorial" },
        { value: "GW", text: "Guinea-Bissau" },
        { value: "GY", text: "Guyana" },
        { value: "HT", text: "Haití" },
        { value: "XH", text: "Holanda" },
        { value: "HN", text: "Honduras" },
        { value: "HK", text: "Hong Kong" },
        { value: "HU", text: "Hungría" },
        { value: "IN", text: "India" },
        { value: "ID", text: "Indonesia" },
        { value: "IQ", text: "Irak" },
        { value: "IR", text: "Irán" },
        { value: "IE", text: "Irlanda" },
        { value: "BV", text: "Isla Bouvet" },
        { value: "JE", text: "Isla de Jersey" },
        { value: "IM", text: "Isla de Man" },
        { value: "CX", text: "Isla de Navidad" },
        { value: "NF", text: "Isla Norfolk" },
        { value: "IS", text: "Islandia" },
        { value: "KY", text: "Islas Caimán" },
        { value: "CC", text: "Islas Cocos (Keeling)" },
        { value: "CK", text: "Islas Cook" },
        { value: "AX", text: "Islas de Aland" },
        { value: "FO", text: "Islas Feroe" },
        { value: "HM", text: "Islas Heard y McDonald" },
        { value: "FK", text: "Islas Malvinas" },
        { value: "MP", text: "Islas Marianas del Norte" },
        { value: "MH", text: "Islas Marshall" },
        {
          value: "UM",
          text: "Islas periféricas menores de los Estados Unidos",
        },
        { value: "PN", text: "Islas Pitcairn" },
        { value: "SB", text: "Islas Salomón" },
        { value: "TK", text: "Islas Tokelau" },
        { value: "VI", text: "Islas Vírgenes Americanas" },
        { value: "VG", text: "Íslas Vírgenes Británicas" },
        { value: "WF", text: "Islas Wallis y Futuna" },
        { value: "IL", text: "Israel" },
        { value: "IT", text: "Italia" },
        { value: "JM", text: "Jamaica" },
        { value: "JP", text: "Japón" },
        { value: "JO", text: "Jordania" },
        { value: "KZ", text: "Kazajistán" },
        { value: "KE", text: "Kenia" },
        { value: "KG", text: "Kirguistán" },
        { value: "KI", text: "Kiribati" },
        { value: "XK", text: "Kosovo" },
        { value: "KW", text: "Kuwait" },
        { value: "LA", text: "Laos" },
        { value: "LS", text: "Lesoto" },
        { value: "LV", text: "Letonia" },
        { value: "LB", text: "Líbano" },
        { value: "LR", text: "Liberia" },
        { value: "LY", text: "Libia" },
        { value: "LI", text: "Liechtenstein" },
        { value: "LT", text: "Lituania" },
        { value: "LU", text: "Luxemburgo" },
        { value: "MO", text: "Macao" },
        { value: "MK", text: "Macedonia del Norte" },
        { value: "MG", text: "Madagascar" },
        { value: "MY", text: "Malasia" },
        { value: "MW", text: "Malauí" },
        { value: "MV", text: "Maldivas" },
        { value: "ML", text: "Mali" },
        { value: "MT", text: "Malta" },
        { value: "MA", text: "Marruecos" },
        { value: "MQ", text: "Martinica" },
        { value: "MU", text: "Mauricio" },
        { value: "MR", text: "Mauritania" },
        { value: "YT", text: "Mayotte" },
        { value: "MX", text: "México" },
        { value: "FM", text: "Micronesia" },
        { value: "MD", text: "Moldavia" },
        { value: "MC", text: "Mónaco" },
        { value: "MN", text: "Mongolia" },
        { value: "ME", text: "Montenegro" },
        { value: "MS", text: "Montserrat" },
        { value: "MZ", text: "Mozambique" },
        { value: "MM", text: "Myanmar" },
        { value: "NA", text: "Namibia" },
        { value: "NR", text: "Nauru" },
        { value: "NP", text: "Nepal" },
        { value: "NI", text: "Nicaragua" },
        { value: "NE", text: "Níger" },
        { value: "NG", text: "Nigeria" },
        { value: "NU", text: "Niue" },
        { value: "NO", text: "Noruega" },
        { value: "NC", text: "Nueva Caledonia" },
        { value: "NZ", text: "Nueva Zelanda" },
        { value: "OM", text: "Omán" },
        { value: "NL", text: "Países Bajos" },
        { value: "PK", text: "Pakistán" },
        { value: "PW", text: "Palaos" },
        { value: "PS", text: "Palestina, estado de" },
        { value: "PA", text: "Panamá" },
        { value: "PG", text: "Papúa Nueva Guinea" },
        { value: "PY", text: "Paraguay" },
        { value: "PE", text: "Perú" },
        { value: "PF", text: "Polinesia Francesa" },
        { value: "PL", text: "Polonia" },
        { value: "PT", text: "Portugal" },
        { value: "PR", text: "Puerto Rico" },
        { value: "QA", text: "Qatar" },
        { value: "GB", text: "Reino Unido" },
        { value: "CF", text: "República Centroafricana" },
        { value: "CZ", text: "República Checa" },
        { value: "SS", text: "República del Sudán del Sur" },
        { value: "CD", text: "República Democrática del Congo" },
        { value: "DO", text: "República Dominicana" },
        { value: "RE", text: "Reunión" },
        { value: "RW", text: "Ruanda" },
        { value: "RO", text: "Rumanía" },
        { value: "EH", text: "Sahara Occidental" },
        { value: "BL", text: "Saint Barthélemy" },
        { value: "MF", text: "Saint Martin" },
        { value: "WS", text: "Samoa" },
        { value: "AS", text: "Samoa Americana" },
        { value: "KN", text: "San Cristóbal y Nieves" },
        { value: "SM", text: "San Marino" },
        { value: "PM", text: "San Pedro y Miquelón" },
        { value: "VC", text: "San Vicente" },
        { value: "SH", text: "Santa Elena" },
        { value: "LC", text: "Santa Lucía" },
        { value: "ST", text: "Santo Tomé y Príncipe" },
        { value: "SN", text: "Senegal" },
        { value: "RS", text: "Serbia" },
        { value: "CS", text: "Serbia y Montenegro (OBSOLETO)" },
        { value: "SC", text: "Seychelles" },
        { value: "SL", text: "Sierra Leona" },
        { value: "SG", text: "Singapur" },
        { value: "SX", text: "Sint Maarten" },
        { value: "SY", text: "Siria" },
        { value: "SO", text: "Somalia" },
        { value: "LK", text: "Sri Lanka" },
        { value: "XSZ", text: "Suazilandia" },
        { value: "ZA", text: "Sudáfrica" },
        { value: "SD", text: "Sudán" },
        { value: "SE", text: "Suecia" },
        { value: "CH", text: "Suiza" },
        { value: "GS", text: "Sur de Georgia e Islas Sándwich del Sur" },
        { value: "SR", text: "Surinam" },
        { value: "SJ", text: "Svalbard" },
        { value: "TH", text: "Tailandia" },
        { value: "TW", text: "Taiwán" },
        { value: "TZ", text: "Tanzania" },
        { value: "TJ", text: "Tayikistán" },
        { value: "IO", text: "Territorio Británico del Océano Índico" },
        { value: "TF", text: "Territorios Australes Franceses" },
        { value: "TL", text: "Timor Oriental" },
        { value: "TG", text: "Togo" },
        { value: "TO", text: "Tonga" },
        { value: "TT", text: "Trinidad y Tobago" },
        { value: "TN", text: "Túnez" },
        { value: "TC", text: "Turcas y Caicos" },
        { value: "TM", text: "Turkmenistán" },
        { value: "TR", text: "Turquía" },
        { value: "TV", text: "Tuvalu" },
        { value: "UA", text: "Ucrania" },
        { value: "UG", text: "Uganda" },
        { value: "UY", text: "Uruguay" },
        { value: "UZ", text: "Uzbekistán" },
        { value: "VU", text: "Vanuatu" },
        { value: "VE", text: "Venezuela" },
        { value: "VN", text: "Vietnam" },
        { value: "YE", text: "Yemen" },
        { value: "DJ", text: "Yibuti" },
        { value: "ZM", text: "Zambia" },
        { value: "ZW", text: "Zimbabue" },
      ],
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    putNewPassword: {
      get() {
        return this.$store.getters["auth/getPutNewPassword"];
      },
      set(val) {
        this.$store.commit("auth/setPutNewPassword", val);
      },
    },
  },
  watch: {
    putNewPassword() {
      if (this.putNewPassword) {
        console.log("SI");
        this.step = 6;
      } else {
        console.log("NO");
      }
    },
  },
  async created() {
    this.setPutNewPassword(false);
    console.log("Browser Language = " + navigator.language);
    console.log("Language = " + navigator.language.startsWith("en"));

    if (
      navigator.language.startsWith("en") &&
      (localStorage.getItem("currentLanguage") === null ||
        localStorage.getItem("currentLanguage") == "es")
    ) {
      this.setCurrentLanguage("en");
    } else if (
      navigator.language.startsWith("es") &&
      (localStorage.getItem("currentLanguage") === null ||
        localStorage.getItem("currentLanguage") == "en")
    ) {
      this.setCurrentLanguage("es");
    }

    localStorage.removeItem("user");
    localStorage.removeItem("cart");
    localStorage.removeItem("currentCustomer");
  },
  methods: {
    ...mapActions({
      login: "auth/login",
      signin: "auth/signin",
      forgot: "auth/forgot",
      newPass: "auth/newPassword",
    }),
    ...mapMutations({
      setShowModalDisclaimer: "auth/setShowModalDisclaimer",
      setLoading: "app/setLoading",
      setPutNewPassword: "auth/setPutNewPassword",
      setCurrentLanguage: "app/setCurrentLanguage",
    }),
    async openModalLogin() {
      this.setLoading(true);
      await this.setShowModalDisclaimer(true);
      this.setLoading(false);
    },
    validationFormLogin() {
      var setShowModalDisclaimer = this.setShowModalDisclaimer;
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          const { username, password } = this;

          if (username && password) {
            this.login({
              username,
              password,
              setShowModalDisclaimer,
              toast: this.$toast,
            });
          }
        }
      });
    },
    validationFormSignin() {
      var setShowModalDisclaimer = this.setShowModalDisclaimer;
      this.$refs.signinValidation.validate().then((success) => {
        console.log(success);
        if (success) {
          console.log(this);
          const {
            signinUsername,
            signinPassword,
            signinLanguage,
            signinEmail,
            signinMobile,
            signinCodCli,
            signinEmpresa,
            signinCif,
            signinCargo,
            signinCountry,
          } = this;

          if (
            signinUsername &&
            signinPassword &&
            signinLanguage &&
            signinEmail &&
            signinEmpresa &&
            signinCargo &&
            signinCountry
          ) {
            console.log(signinUsername);
            this.signin({
              name: signinUsername,
              password: signinPassword,
              language: signinLanguage,
              email: signinEmail,
              phone: signinMobile,
              codcli: signinCodCli,
              empresa: signinEmpresa,
              cif: signinCif,
              cargo: signinCargo,
              country: signinCountry,
              setShowModalDisclaimer,
              toast: this.$toast,
            });
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Alguno de los campos del formulario no es correcto",
              icon: "EditIcon",
              variant: "light",
            },
          });
        }
      });
    },
    validationFormForgot() {
      var setShowModalDisclaimer = this.setShowModalDisclaimer;
      this.$refs.forgotValidation.validate().then((success) => {
        if (success) {
          const { forgotEmail } = this;

          if (forgotEmail) {
            console.log(forgotEmail);
            this.forgot({
              email: forgotEmail,
              setShowModalDisclaimer,
              toast: this.$toast,
            });
            this.step = 5;
          }
        }
      });
    },
    validationFormNewPassword() {
      var setShowModalDisclaimer = this.setShowModalDisclaimer;
      this.$refs.newPasswordValidation.validate().then((success) => {
        if (success) {
          const { newPassword, password, username } = this;

          if (newPassword) {
            console.log(username);
            console.log(password);
            console.log(newPassword);

            this.newPass({
              username: username,
              oldPassword: password,
              newPassword: newPassword,
              setShowModalDisclaimer,
              toast: this.$toast,
            });
            this.step = 1;
            this.password = "";
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
