<template>
  <div>
    <b-modal
      id="modalDisclaimer"
      hide-footer
      centered
      size="lg"
      v-model="modalDisclaimerShow"
    >
      <b-row>
        <b-col cols="12">
          <div class="my-2">
            <p class="h1 text-center">{{ $i18n.messages.legal1 }}</p>
            <p class="text-center" style="margin-top: 30px;">
              {{ $i18n.messages.legal2 }}
            </p>
            <p class="text-center">{{ $i18n.messages.legal3 }}</p>
            <div
              class="d-flex flex-wrap justify-content-center"
              style="margin-top: 30px;"
            >
              <b-form-checkbox
                id="checkbox-1"
                v-model="accepted"
                name="checkbox-1"
              >
                {{ $i18n.messages.legal4 }}
              </b-form-checkbox>
            </div>
            <div class="mt-2 d-flex justify-content-center">
              <b-button
                variant="primary"
                @click="buttonClicked"
                :disabled="!accepted"
              >
                {{ $i18n.messages.legal5 }}
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  BFormInput,
  BInputGroup,
  BRow,
  BCol,
  VBModal,
  BProgress,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters, mapMutations } from "vuex";
import store from "@/store";

export default {
  components: {
    BModal,
    BButton,
    BFormInput,
    BInputGroup,
    BRow,
    BCol,
    BProgress,
    BFormCheckbox,
  },

  data() {
    return { status: "not_accepted", accepted: false };
  },
  computed: {
    modalDisclaimerShow: {
      get() {
        return this.$store.getters["auth/getShowModalDisclaimer"];
      },
      set(val) {
        this.$store.commit("auth/setShowModalDisclaimer", val);
      },
    },
  },
  methods: {
    buttonClicked() {
      console.log("AAA");
      console.log(this.$parent);
      store.dispatch("auth/acceptedConditions");
    },
  },
  actions: {
    ...mapActions({
      acceptedConditions: "auth/acceptedConditions",
    }),
  },
  watch: {
    modalDisclaimerShow() {},
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
};
</script>
